/**
 * Created by qianshi on 2024/5/18.
 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, DatePicker, Upload, Menu, Dropdown, Spin, message } from 'antd';
// import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { Table, Button, Modal, Form, Input, Select, Tabs } from 'antd';
import {
    queryKeywordIncome,
    enterKeywordIncome,
    queryListMovieApplying,
    updateMovieKeywordStatus,
    enterKeywordIncomeBatch,
    queryAllNovelPlatform,
    exportExcel,
    queryKeywordIncomeDetail,
} from '../../api/api';
import moment from 'moment';
import AppMessage from '../../utils/AppMessage';
import './index.less';
import Icon from '@ant-design/icons/lib/components/Icon';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

type KeywordsProps = {};

const columns = [
    {
        title: '关键词ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        fixed: true,
    },
    {
        title: '关键词名称',
        dataIndex: 'keyword',
        key: 'keyword',
        width: 120,
        fixed: true,
    },
    {
        title: '生成时间',
        dataIndex: 'gmtCreate',
        key: 'gmtCreate',
        width: 150,
        render: (val: any) => {
            return moment(val)?.format('YYYY-MM-DD HH:mm:ss');
        },
    },
    {
        title: '平台名称',
        dataIndex: 'novelPlatform',
        key: 'novelPlatform',
        width: 100,
    },
    {
        title: '小说ID',
        dataIndex: 'novelId',
        key: 'novelId',
        width: 100,
    },
    {
        title: '小说名称',
        dataIndex: 'novelName',
        key: 'novelName',
        width: 120,
    },
    {
        title: '完整出单量(拉新)',
        dataIndex: 'totalAcquisition',
        key: 'totalAcquisition',
        width: 150,
    },
    {
        title: '完整出单量(拉旧)',
        dataIndex: 'totalAcquisitionOld',
        key: 'totalAcquisitionOld',
        width: 150,
    },
    {
        title: '平台扣单量(拉新)',
        dataIndex: 'platformAcquisitionDeduction',
        key: 'platformAcquisitionDeduction',
        width: 150,
    },
    {
        title: '平台扣单量(拉旧)',
        dataIndex: 'platformAcquisitionOldDeduction',
        key: 'platformAcquisitionOldDeduction',
        width: 150,
    },
    {
        title: '师傅扣单量(拉新)',
        dataIndex: 'masterUserAcquisitionDeduction',
        key: 'masterUserAcquisitionDeduction',
        width: 150,
    },
    {
        title: '师傅扣单量(拉旧)',
        dataIndex: 'masterUserAcquisitionOldDeduction',
        key: 'masterUserAcquisitionOldDeduction',
        width: 150,
    },
    {
        title: '达人展示单量(拉新)',
        dataIndex: 'displayAcquisition',
        key: 'displayAcquisition',
        width: 200,
    },
    {
        title: '达人展示单量(拉旧)',
        dataIndex: 'displayAcquisitionOld',
        key: 'displayAcquisitionOld',
        width: 200,
    },
    {
        title: '达人完整收益',
        dataIndex: 'totalIncome',
        key: 'totalIncome',
        width: 150,
    },
    {
        title: '达人扣单收益(拉新)',
        dataIndex: 'incomeDeduction',
        key: 'incomeDeduction',
        width: 200,
    },
    {
        title: '达人扣单收益(拉旧)',
        dataIndex: 'incomeOldDeduction',
        key: 'incomeOldDeduction',
        width: 200,
    },
    {
        title: '达人展示收益',
        dataIndex: 'displayIncome',
        key: 'displayIncome',
        width: 150,
    },
    // {
    //     title: '激励订单量',
    //     dataIndex: 'address',
    //     key: 'address',
    //     width: 150,
    // },
    {
        title: '达人名称',
        dataIndex: 'userName',
        key: 'userName',
        width: 100,
    },
    {
        title: '达人ID',
        dataIndex: 'userId',
        key: 'userId',
        width: 100,
    },
    {
        title: '师傅名称',
        dataIndex: 'masterUserName',
        key: 'masterUserName',
        width: 100,
    },
    {
        title: '邀请码',
        dataIndex: 'inviteCode',
        key: 'inviteCode',
        width: 100,
    },
    {
        title: '注册时间',
        dataIndex: 'registerTime',
        key: 'registerTime',
        width: 100,
        render: (val: any) => {
            return val ? moment(val)?.format('YYYY-MM-DD HH:mm:ss') : '-';
        },
    },
];

const movieKeywordsSatatus: any = {
    APPLYING: '待审核',
    APPLY_SUCCESS: '待发布',
    REJECTED: '审核不通过',
    PUBLISHING: '发布中',
    PUBLISHED: '发布成功',
};

const buttonItemLayout = { wrapperCol: { span: 14, offset: 4 } };

const Keywords = (props: KeywordsProps) => {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [showFormMovie, setShowFormMovie] = useState<boolean>(false);

    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [keyWordsData, setKeyWordsData] = useState<any>([]);

    const [showBatchForm, setShowBatchForm] = useState<boolean>(false);

    const [pageInfo, setPageInfo] = useState<any>({
        pageIndex: 1,
        pageSize: 10,
        total: 0,
    });
    const [tab, setTab] = useState<any>('1');
    const [statusSelect, setStatusSelect] = useState<any>('');
    const [dateSelected, setDateSelected] = useState<any>({});
    const onDateChange = (dates: any, dateStrings: string[]) => {
        dates === null
            ? setDateSelected({})
            : setDateSelected({
                  fromDate: dates[0].valueOf(),
                  toDate: dates[1].valueOf(),
              });
    };

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [form0] = Form.useForm();
    const [form3] = Form.useForm();

    const [uploadFileList, setUploadFileList] = useState<any>([]); // 上传的文件

    const [showUploadMask, setShowUploadMask] = useState<boolean>(false);
    const [novelPlatformList, setNovelPlatformList] = useState<[any]>([
        {
            code: '',
            name: '',
        },
    ]);

    const [keywordExportButtonDisabled, setKeywordExportButtonDisabled] = useState<boolean>(false);

    useEffect(() => {
        queryKeyWord({
            pageIndex: pageInfo.pageIndex,
            pageSize: pageInfo.pageSize,
        });
        queryNovelPlatform();
    }, []);

    const queryNovelPlatform = () => {
        queryAllNovelPlatform({})
            .then((res: any) => {
                if ('success' in res && res.success) {
                    setNovelPlatformList(res.data);
                } else {
                    console.error('查询小说平台失败', res);
                }
            })
            .catch((err: any) => {
                console.error('查询小说平台失败', err);
            });
    };

    const columnsMovie = [
        {
            title: '关键词ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            fixed: true,
        },
        {
            title: '小说id（内部）',
            dataIndex: 'novelid',
            key: 'novelid',
            width: 100,
        },
        {
            title: '小说id（外部）',
            dataIndex: 'outerid',
            key: 'outerid',
            width: 100,
        },
        {
            title: '关键词名称',
            dataIndex: 'keyword',
            key: 'keyword',
            width: 120,
        },
        {
            title: '推广id',
            dataIndex: 'grantid',
            key: 'grantid',
            width: 120,
        },
        {
            title: '生成时间',
            dataIndex: 'gmtcreate',
            key: 'gmtcreate',
            width: 150,
            render: (val: any) => {
                return moment(val)?.format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: '生成时间',
            dataIndex: 'gmtmodified',
            key: 'gmtmodified',
            width: 150,
            render: (val: any) => {
                return moment(val)?.format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: '平台名称',
            dataIndex: 'novelplatform',
            key: 'novelplatform',
            width: 100,
        },

        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (val: any, record: any) => {
                return (
                    <>
                        <div>{movieKeywordsSatatus[val]}</div>
                        {record?.failreason && <span>失败原因：{record?.failreason}</span>}
                    </>
                );
            },
        },
        {
            title: '操作',
            key: 'action',
            width: 100,
            render: (val: any, record: any) => (
                <span>
                    {val !== 'REJECTED' && val !== 'PUBLISHED' ? (
                        <Button
                            type="link"
                            onClick={() => {
                                setShowFormMovie(record.id);
                            }}
                        >
                            修改状态
                        </Button>
                    ) : (
                        ''
                    )}
                </span>
            ),
        },
    ];

    const keywordIncomeDetailColumns = [
        {
            title: '关键词ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            fixed: true,
        },
        {
            title: '关键词名称',
            dataIndex: 'keyword',
            key: 'keyword',
            width: 120,
            fixed: true,
        },
        {
            title: '平台名称',
            dataIndex: 'novelplatform',
            key: 'novelplatform',
            width: 100,
            fixed: true,
        },
        {
            title: '总收益',
            dataIndex: 'allincome',
            key: 'allincome',
            width: 150,
            fixed: true,
            render: (val: any) => {
                return val ? val / 100 : '0';
            },
        },
        {
            title: '拉新数',
            dataIndex: 'acquisition',
            key: 'acquisition',
            width: 150,
        },
        {
            title: '拉新单价',
            dataIndex: 'acquisitionunitprice',
            key: 'acquisitionunitprice',
            width: 150,
            render: (val: any) => {
                return val ? val / 100 : '0';
            },
        },
        {
            title: '拉旧数',
            dataIndex: 'acquisitionold',
            key: 'acquisitionold',
            width: 100,
        },
        {
            title: '拉旧单价',
            dataIndex: 'acquisitionoldunitprice',
            key: 'acquisitionoldunitprice',
            width: 100,
            render: (val: any) => {
                return val ? val / 100 : '0';
            },
        },
        {
            title: '拉新收益',
            dataIndex: 'acquisitionincome',
            key: 'acquisitionincome',
            width: 100,
            render: (val: any) => {
                return val ? val / 100 : '0';
            },
        },
        {
            title: '拉旧收益',
            dataIndex: 'acquisitionoldincome',
            key: 'acquisitionoldincome',
            width: 100,
            render: (val: any) => {
                return val ? val / 100 : '0';
            },
        },
        {
            title: '生成时间',
            dataIndex: 'gmtcreate',
            key: 'gmtcreate',
            width: 150,
            render: (val: any) => {
                return moment(val)?.format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: '收益展示日期',
            dataIndex: 'displayDate',
            key: 'displayDate',
            width: 200,
            render: (val: any) => {
                return val ? moment(val)?.format('YYYY-MM-DD HH:mm:ss') : '-';
            },
        },
    ];

    const queryKeyWord = (param: any) => {
        setTableLoading(true);
        queryKeywordIncome({ ...param })
            .then((res: any) => {
                if (res.success) {
                    setKeyWordsData(res.data);
                    setPageInfo({
                        pageIndex: param.pageIndex,
                        pageSize: param.pageSize,
                        total: res.totalCount,
                    });
                }
            })
            .finally(() => {
                setTableLoading(false);
            });
    };

    const queryMovieKeyWord = (param: any) => {
        setTableLoading(true);
        queryListMovieApplying({ ...param })
            .then((res: any) => {
                if (res.success) {
                    setKeyWordsData(res.data);
                    setPageInfo({
                        pageIndex: param.pageIndex,
                        pageSize: param.pageSize,
                        total: res.totalCount,
                    });
                }
            })
            .finally(() => {
                setTableLoading(false);
            });
    };

    const queryKeyWordIncomeDetail = (param: any) => {
        setTableLoading(true);
        queryKeywordIncomeDetail({ ...param })
            .then((res: any) => {
                if (res.success) {
                    setKeyWordsData(res.data);
                    setPageInfo({
                        pageIndex: param.pageIndex,
                        pageSize: param.pageSize,
                        total: res.totalCount,
                    });
                }
            })
            .finally(() => {
                setTableLoading(false);
            });
    };

    const queryTableData = () => {
        const param = form2.getFieldsValue();
        param.keyword = param?.keyword?.replaceAll('\n', ',');
        doQuery(tab, param);
    };

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const updatedPageInfo = {
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            filters,
            ...sorter,
        };

        const param = form2.getFieldsValue();
        doQuery(tab, { ...param, ...updatedPageInfo });
        setPageInfo(updatedPageInfo);
    };

    function doQuery(tab: any, param: any) {
        switch (tab) {
            case '1':
                queryKeyWord({ ...param });
                break;
            case '2':
                queryMovieKeyWord({ ...param });
                break;
            case '3':
                queryKeyWordIncomeDetail({ ...param, ...dateSelected });
                break;
        }
    }

    const addKeyWord = () => {
        const param = form.getFieldsValue();
        param.createDate = param.createDate?.format('YYYY-MM-DD 00:00:00');
        enterKeywordIncome(param).then((res: any) => {
            if (res.success) {
                AppMessage.showInfo('创建成功');
                setShowForm(false);
            } else {
                AppMessage.showError(res.errMsg);
            }
        });
    };

    const changeStatus = () => {
        const param = form0.getFieldsValue();
        param.id = showFormMovie;
        updateMovieKeywordStatus(param).then((res: any) => {
            if (res.success) {
                AppMessage.showInfo('修改成功');
                setShowFormMovie(false);
                queryTableData();
            }
        });
    };

    const onTabChange = (val: string) => {
        setTab(val);
        setPageInfo({
            pageIndex: 1,
            pageSize: 10,
            total: 0,
        });
        doQuery(val, { pageIndex: 1, pageSize: 10 });
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <a onClick={() => setShowForm(true)}>录入关键词收益</a>
            </Menu.Item>
            <Menu.Item>
                <a onClick={() => setShowBatchForm(true)}>批量录入关键词收益</a>
            </Menu.Item>
            <Menu.Item disabled={keywordExportButtonDisabled}>
                <Spin delay={200} spinning={keywordExportButtonDisabled}>
                    <a onClick={() => exportBySearchForm()}>导出关键词收益</a>
                </Spin>
            </Menu.Item>
        </Menu>
    );

    const optionMenu =
        tab === '1' ? (
            <Dropdown overlay={menu}>
                <Button className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    更多功能
                </Button>
            </Dropdown>
        ) : (
            <></>
        );

    const customUpload = (params: RcCustomRequestOptions) => {
        setShowUploadMask(true);
        enterKeywordIncomeBatch(params.data, params?.file)
            .then((res) => {
                if (res.status === 200) {
                    const result: any = res.data;
                    if ('success' in result && result.success) {
                        AppMessage.showSuccess('上传成功');
                        setShowBatchForm(false);
                    } else {
                        AppMessage.showError(result.errMsg);
                    }
                } else {
                    console.error('上传失败', res);
                    AppMessage.showError('系统异常，请联系管理员');
                }
            })
            .finally(() => {
                setShowUploadMask(false);
            });
    };

    const exportBySearchForm = () => {
        setKeywordExportButtonDisabled(true);
        message.info('数据导出中，请勿重复点击');
        const param = form2.getFieldsValue();
        exportExcel({
            scene: 'KEYWORD',
            data: {
                ...param,
            },
        })
            .then((res: any) => {
                if ('success' in res && res.success) {
                    AppMessage.showSuccess('导出成功');
                    window.open(res.data);
                    console.log('导出结果', res);
                } else {
                    console.error('导出失败', res);
                    AppMessage.showError('系统异常，请联系管理员');
                    AppMessage.showError(res.errMsg);
                }
            })
            .catch((error) => {
                console.error('导出失败', error);
                AppMessage.showError('系统异常，请联系管理员');
            })
            .finally(() => {
                setKeywordExportButtonDisabled(false);
            });
    };

    function renderSelector() {
        switch (tab) {
            case '1':
                return (
                    <>
                        <Form.Item name="keyword" label="关键词名称">
                            {/* <Input /> */}
                            <TextArea rows={1} allowClear={true} maxLength={100} />
                        </Form.Item>
                        <Form.Item name="userNo" label="达人账号名称">
                            <Input />
                        </Form.Item>
                        <Form.Item name="mediaAccount" label="达人媒体账号名称">
                            <Input />
                        </Form.Item>
                        <Form.Item name="phone" label="达人电话号码">
                            <Input />
                        </Form.Item>
                        <Form.Item name="userId" label="达人ID">
                            <Input />
                        </Form.Item>
                    </>
                );
            case '2':
                return (
                    <>
                        <Form.Item name="novelPlatform" label="小说平台">
                            <Select mode="multiple">
                                {novelPlatformList.map((item) => (
                                    <Select.Option value={item.code}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="keyword" label="关键词名称">
                            {/* <Input /> */}
                            <TextArea rows={1} allowClear={true} maxLength={100} />
                        </Form.Item>
                    </>
                );
            case '3':
                return (
                    <>
                        <Form.Item name="keyword" label="关键词名称">
                            {/* <Input /> */}
                            <TextArea rows={1} allowClear={true} maxLength={100} />
                        </Form.Item>

                        <Form.Item name="novelPlatform" label="小说平台">
                            <Select allowClear={true}>
                                {novelPlatformList?.map((item) => (
                                    <Select.Option value={item.code}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="时间">
                            <RangePicker
                                ranges={{
                                    Today: [moment().startOf('day'), moment().endOf('day')],
                                    'This Week': [moment().startOf('week'), moment().endOf('week')],
                                    'This Month': [
                                        moment().startOf('month'),
                                        moment().endOf('month'),
                                    ],
                                }}
                                showTime
                                format="YYYY/MM/DD HH:mm:ss"
                                onChange={onDateChange}
                            />
                        </Form.Item>
                    </>
                );
            default:
                return <></>;
        }
    }

    function showTotalDiv() {
        return `Total: ${pageInfo.total}`;
    }
    const renderColumns = () => {
        switch (tab) {
            case '1':
                return columns;
            case '2':
                return columnsMovie;
            case '3':
                return keywordIncomeDetailColumns;
            default:
                return [{}];
        }
    };

    return (
        <div className="gutter-example button-demo">
            <Row gutter={16}>
                <Col className="gutter-row" md={24}>
                    <Tabs activeKey={tab} onChange={(v) => onTabChange(v)}>
                        <Tabs.TabPane tab="短视频" key="1"></Tabs.TabPane>
                        <Tabs.TabPane tab="短剧" key="2"></Tabs.TabPane>
                        <Tabs.TabPane tab="关键词收益" key="3"></Tabs.TabPane>
                    </Tabs>
                    <div className="gutter-box">
                        <Card bordered={false}>
                            <Form
                                className={'keyword_search'}
                                layout="inline"
                                form={form2}
                                name="form_in_modal"
                                initialValues={{}}
                            >
                                {renderSelector()}
                                <Form.Item {...buttonItemLayout}>
                                    <Button type="primary" onClick={() => queryTableData()}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                        {/* 数据 */}
                        <Card bordered={false} title={optionMenu}>
                            <Table
                                loading={tableLoading}
                                onChange={handleTableChange}
                                columns={renderColumns()}
                                dataSource={keyWordsData}
                                scroll={{ x: 800 }}
                                pagination={{
                                    ...pageInfo,
                                    pageSizeOptions: [10, 20],
                                    current: pageInfo.pageIndex,
                                    showTotal: showTotalDiv,
                                }}
                            />
                        </Card>
                    </div>
                </Col>
            </Row>
            {/* 弹窗 */}
            {showForm && (
                <Modal
                    visible={showForm}
                    title="录入关键词收益"
                    okText="提交"
                    onCancel={() => {
                        setShowForm(false);
                    }}
                    onOk={() => addKeyWord()}
                >
                    <Form layout="vertical" form={form} name="form_in_modal" initialValues={{}}>
                        <Form.Item
                            name="novelPlatform"
                            label="小说平台"
                            rules={[{ required: true, message: '必填' }]}
                        >
                            <Select>
                                {novelPlatformList.map((item) => (
                                    <Select.Option value={item.code}>{item.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="keyword"
                            label="关键词"
                            rules={[{ required: true, message: '必填' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="acquisition"
                            label="拉新数"
                            rules={[{ required: true, message: '必填' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="acquisitionOld"
                            label="拉旧数"
                            rules={[{ required: true, message: '必填' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="createDate"
                            label="创建时间"
                            rules={[{ required: true, message: '必填' }]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Form>
                </Modal>
            )}

            {showFormMovie && (
                <Modal
                    visible={showFormMovie}
                    title="修改状态"
                    okText="提交"
                    cancelText="取消"
                    onCancel={() => {
                        setShowFormMovie(false);
                    }}
                    onOk={() => changeStatus()}
                >
                    <Form layout="vertical" form={form0} name="form_in_modal" initialValues={{}}>
                        <Form.Item
                            name="status"
                            label="状态选择"
                            rules={[{ required: true, message: '必填' }]}
                        >
                            <Select
                                value={statusSelect}
                                onChange={(val) => {
                                    setStatusSelect(val);
                                }}
                            >
                                {Object.keys(movieKeywordsSatatus).map((i) => {
                                    return (
                                        <Select.Option value={i}>
                                            {movieKeywordsSatatus[i]}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        {form0.getFieldsValue().status === 'REJECTED' && (
                            <Form.Item name="failreason" label="失败原因">
                                <Input />
                            </Form.Item>
                        )}
                    </Form>
                </Modal>
            )}

            {showBatchForm && (
                <Modal
                    visible={showBatchForm}
                    title="批量录入关键词收益"
                    footer={null}
                    confirmLoading={true}
                    onCancel={() => {
                        setShowBatchForm(false);
                    }}
                >
                    <Spin tip="正在处理中，请稍后..." spinning={showUploadMask}>
                        <Form
                            layout="vertical"
                            form={form3}
                            name="form_in_modal"
                            initialValues={{}}
                        >
                            <div>
                                <Row>
                                    <Col>上传说明：</Col>
                                </Row>
                                <Row>
                                    <Col style={{ paddingTop: 10, paddingBottom: 20 }}>
                                        请使用特定的模板，不要随意找模板。
                                        <a href="http://static.aijijiang.com/excel/haoyin/2024-09-25.xlsx">
                                            下载模板链接
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Item
                                            name="url"
                                            rules={[{ required: true, message: '必填' }]}
                                        >
                                            <Upload
                                                name={'file'}
                                                action={
                                                    'http://api-admin.aijijiang.com/excel/syncImport'
                                                }
                                                data={{ scene: 'KEYWORD_INCOME' }}
                                                fileList={uploadFileList}
                                                customRequest={customUpload}
                                            >
                                                <Button>
                                                    <Icon type="upload" />
                                                    Click to Upload{' '}
                                                </Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Spin>
                </Modal>
            )}
        </div>
    );
};

export default Keywords;
