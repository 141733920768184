/**
 * Created by qianshi on 2024/5/31.
 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Image, DatePicker, Dropdown, Menu, message, Spin } from 'antd';
// import BreadcrumbCustom from '../widget/BreadcrumbCustom';
import { Table, Button, Modal, Form, Input, Select, Upload } from 'antd';
import {
    queryWithdrawal,
    confirmWithdrawal,
    withdrawalDetail,
    getUploadToken,
    exportExcel,
} from '../../api/api';
import moment from 'moment';
import AppMessage from '../../utils/AppMessage';
import './index.less';
import * as qiniu from 'qiniu-js';
import { aesDecode } from '../../api/cryptoUtil';
// import { UploadOutlined } from '@ant-design/icons';
// import type { UploadProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

type WithdrawalProps = {};

const { RangePicker } = DatePicker;

const exportSecret = '46EBA22EF5204DD5B110A1F730513965';

const statusObj: any = {
    CONFIRMING: '待确认',
    REJECTED: '已拒绝',
    RECEIVED: '已收款',
};

const detailObj: any = {
    id: 'ID',
    userId: '达人账号id',
    userName: '达人名称',
    masterUserName: '师傅名称',
    registerTime: '注册时间',
    applyTime: '提现申请时间',
    amount: '提现金额',
    status: '提现状态',
    receivedTime: '确认收款时间',
    withdrawCnt: '历史累计提现金额',
    totalIncome: '累计总收益',
};

//     labelCol: {
//         xs: { span: 24 },
//         sm: { span: 8 },
//     },
//     wrapperCol: {
//         xs: { span: 24 },
//         sm: { span: 16 },
//     },
// };

const buttonItemLayout = { wrapperCol: { span: 14, offset: 4 } };

const Withdrawal = (props: WithdrawalProps) => {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [withdrawalData, setWithdrawalData] = useState<any>([]);
    const [confirmId, setConfirmId] = useState<any>(''); // 确认ID
    const [detail, setDetail] = useState<any>({}); // 详情信息
    const [uploadUrl, setUploadUrl] = useState<any>(''); // 上传的图片
    const [dateSelected, setDateSelected] = useState<any>({});
    const [exportButtonDisabled, setExportButtonDisabled] = useState<boolean>(false);

    const [pageInfo, setPageInfo] = useState<any>({
        pageIndex: 1,
        pageSize: 10,
        total: 0,
    });

    const [form] = Form.useForm();
    const [form2] = Form.useForm();

    const optionRender = (val: any, record: any) => {
        return (
            <>
                <Button
                    type="link"
                    onClick={() => {
                        withdrawalDetail({ id: record.id }).then((res: any) => {
                            if (res.success) {
                                const resData = {
                                    ...res?.data,
                                    receivedTime: res?.data.receivedTime
                                        ? moment(res?.data.receivedTime).format(
                                              'YYYY-MM-DD HH:mm:ss'
                                          )
                                        : '',
                                    registerTime: res?.data.registerTime
                                        ? moment(res?.data.registerTime).format(
                                              'YYYY-MM-DD HH:mm:ss'
                                          )
                                        : '',
                                    applyTime: res?.data.applyTime
                                        ? moment(res?.data.applyTime).format('YYYY-MM-DD HH:mm:ss')
                                        : '',
                                };
                                setDetail(resData);
                                form.setFieldsValue(resData);
                                setShowForm(true);
                                setConfirmId(record.id);
                                setUploadUrl(res.data.images?.[0] || '');
                            }
                        });
                    }}
                >
                    {record?.status === 'CONFIRMING' ? '完成提现' : '查看详情'}
                </Button>
            </>
        );
    };

    const columns: any = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            fixed: true,
        },
        {
            title: '达人ID',
            dataIndex: 'userId',
            key: 'userId',
            width: 100,
            fixed: true,
        },
        {
            title: '达人名称',
            dataIndex: 'userName',
            key: 'userName',
            width: 100,
            fixed: true,
        },
        {
            title: '提现金额',
            dataIndex: 'amount',
            key: 'amount',
            width: 100,
        },
        {
            title: '队长收益',
            dataIndex: 'tlAmount',
            key: 'tlAmount',
            width: 100,
        },
        {
            title: '推广收益',
            dataIndex: 'tlGrantAmount',
            key: 'tlGrantAmount',
            width: 100,
        },
        {
            title: '提现状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (val: any) => {
                return statusObj[val] || '';
            },
        },
        {
            title: '确认提现时间',
            dataIndex: 'receivedTime',
            key: 'receivedTime',
            width: 150,
            render: (val: any) => {
                return val ? moment(val)?.format('YYYY-MM-DD HH:mm:ss') : '-';
            },
        },
        {
            title: '申请提现时间',
            dataIndex: 'applyTime',
            key: 'applyTime',
            width: 150,
            render: (val: any) => {
                return val ? moment(val)?.format('YYYY-MM-DD HH:mm:ss') : '-';
            },
        },

        {
            title: '操作',
            dataIndex: 'option',
            key: 'option',
            width: 150,
            render: optionRender,
            fixed: 'right',
        },
    ];

    useEffect(() => {
        queryWithdrawalList({
            pageIndex: pageInfo.pageIndex,
            pageSize: pageInfo.pageSize,
        });
    }, []);

    const queryWithdrawalList = (param: any) => {
        setTableLoading(true);
        queryWithdrawal({ ...param })
            .then((res: any) => {
                if (res.success) {
                    setWithdrawalData(res.data);
                    setPageInfo({
                        pageIndex: param.pageIndex,
                        pageSize: param.pageSize,
                        total: res.totalCount,
                    });
                }
            })
            .finally(() => {
                setTableLoading(false);
            });
    };

    const queryKeyword = () => {
        const param = form2.getFieldsValue();
        queryWithdrawalList({ ...param, ...dateSelected, ...pageInfo });
    };
    const onDateChange = (dates: any, dateStrings: string[]) => {
        dates === null
            ? setDateSelected({})
            : setDateSelected({
                  fromDate: dates[0].valueOf(),
                  toDate: dates[1].valueOf(),
              });
    };
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const param = form2.getFieldsValue();
        queryWithdrawalList({
            ...param,
            ...dateSelected,
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
        });
    };
    const confirm = () => {
        const param = {
            id: confirmId,
            url: uploadUrl,
        };
        confirmWithdrawal(param).then((res: any) => {
            if (res.success) {
                AppMessage.showInfo('确认成功');
                setShowForm(false);
                setDetail({});
                setConfirmId('');
                setUploadUrl('');
                queryWithdrawalList({
                    pageIndex: pageInfo.pageIndex,
                    pageSize: pageInfo.pageSize,
                });
            } else {
                AppMessage.showInfo(res.errMsg);
            }
        });
    };
    const handleUpload = (info: any) => {
        getUploadToken().then((token: any) => {
            const decodeRes: any = JSON.parse(aesDecode(token, exportSecret));
            const putExtra: any = {};
            const config: any = {
                mimeType: 'image/jpeg',
            };
            const observable: any = qiniu.upload(
                info.file.originFileObj,
                null,
                decodeRes?.data?.token,
                putExtra,
                config
            );
            const subscription = observable.subscribe(next, error, (res: any) => {
                setUploadUrl(decodeRes?.data?.prefix + res.key);
            });
            console.log('subscription', subscription);
        });
    };
    const next = (val: any) => {
        console.log('nextVal', val);
    };
    const error = (err: any) => {
        console.log('errVal', err);
    };

    const exportBySearchForm = () => {
        setExportButtonDisabled(true);
        message.info('数据导出中，请勿重复点击');
        const param = form2.getFieldsValue();
        exportExcel({
            scene: 'WITHDRAW',
            data: {
                ...param,
                ...dateSelected,
            },
        })
            .then((res: any) => {
                if ('success' in res && res.success) {
                    AppMessage.showSuccess('导出成功');
                    window.open(res.data);
                    console.log('导出结果', res);
                } else {
                    console.error('导出失败', res);
                    AppMessage.showError('系统异常，请联系管理员');
                    AppMessage.showError(res.errMsg);
                }
            })
            .catch((error) => {
                console.error('导出失败', error);
                AppMessage.showError('系统异常，请联系管理员');
            })
            .finally(() => {
                setExportButtonDisabled(false);
            });
    };

    const menu = (
        <Menu>
            <Menu.Item disabled={exportButtonDisabled}>
                <Spin delay={200} spinning={exportButtonDisabled}>
                    <a onClick={() => exportBySearchForm()}>导出提现记录</a>
                </Spin>
            </Menu.Item>
        </Menu>
    );

    const optionMenu = (
        <Dropdown overlay={menu}>
            <Button className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                更多功能
            </Button>
        </Dropdown>
    );

    return (
        <div className="gutter-example button-demo">
            <Row gutter={16}>
                <Col className="gutter-row" md={24}>
                    <div className="gutter-box">
                        <Card bordered={false}>
                            <Form
                                className={'keyword_search'}
                                layout="inline"
                                form={form2}
                                name="form_in_modal"
                                initialValues={{}}
                            >
                                <Form.Item name="status" label="状态">
                                    <Select>
                                        <Select.Option value="">全部</Select.Option>
                                        <Select.Option value="CONFIRMING">待确认</Select.Option>
                                        <Select.Option value="REJECTED">已拒绝</Select.Option>
                                        <Select.Option value="RECEIVED">已收款</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="userNo" label="达人账号名称">
                                    <Input />
                                </Form.Item>
                                {/* <Form.Item name="mediaAccount" label="达人媒体账号名称">
                                    <Input />
                                </Form.Item> */}
                                <Form.Item name="userId" label="达人ID">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="时间">
                                    <RangePicker
                                        ranges={{
                                            Today: [moment().startOf('day'), moment().endOf('day')],
                                            'This Week': [
                                                moment().startOf('week'),
                                                moment().endOf('week'),
                                            ],
                                            'This Month': [
                                                moment().startOf('month'),
                                                moment().endOf('month'),
                                            ],
                                        }}
                                        showTime
                                        format="YYYY/MM/DD HH:mm:ss"
                                        onChange={onDateChange}
                                    />
                                </Form.Item>

                                <Form.Item {...buttonItemLayout}>
                                    <Button type="primary" onClick={() => queryKeyword()}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>

                        <Card bordered={false} title={optionMenu}>
                            <Table
                                loading={tableLoading}
                                onChange={handleTableChange}
                                columns={columns}
                                dataSource={withdrawalData}
                                scroll={{ x: 800 }}
                                pagination={{ ...pageInfo }}
                            />
                        </Card>
                    </div>
                </Col>
            </Row>

            {showForm && (
                <Modal
                    visible={showForm}
                    title="详细信息"
                    okText={'确认提现'}
                    okButtonProps={detail.status === 'CONFIRMING' ? {} : { disabled: true }}
                    onCancel={() => {
                        setShowForm(false);
                        setDetail({});
                        setConfirmId('');
                        setUploadUrl('');
                        form.setFieldsValue({ url: '' });
                    }}
                    onOk={() => confirm()}
                >
                    <Form
                        form={form}
                        name="form_in_modal"
                        {...{ labelCol: { span: 7 }, wrapperCol: { span: 16 } }}
                    >
                        {Object.keys(detail).map((i) => {
                            if (i === 'images') {
                                return;
                            }
                            return (
                                <Form.Item
                                    // key={i}
                                    name={i}
                                    label={detailObj[i]}
                                    // initialValue={detail[i]}
                                >
                                    {i === 'status' ? (
                                        <Select disabled>
                                            <Select.Option value="CONFIRMING">待确认</Select.Option>
                                            <Select.Option value="REJECTED">已拒绝</Select.Option>
                                            <Select.Option value="RECEIVED">已收款</Select.Option>
                                        </Select>
                                    ) : (
                                        <Input disabled />
                                    )}
                                </Form.Item>
                            );
                        })}
                        <Form.Item
                            name="url"
                            label="上传图片"
                            className="ipload_img_form"
                            rules={[{ required: true, message: '必填' }]}
                        >
                            <Upload
                                name={'file'}
                                action={'http://upload-z2.qiniup.com'}
                                onChange={(info) => {
                                    handleUpload(info);
                                }}
                            >
                                <Button>Click to Upload</Button>
                            </Upload>
                            {uploadUrl && (
                                <Image className={'upload_img'} width={200} src={uploadUrl} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default Withdrawal;
